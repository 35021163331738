* {
    box-sizing: border-box;
  }
  
  body {
    font-family: "IBM Plex Sans", sans-serif;
  }
  
  html,
  body {
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }
  
  a,
  a:visited {
    color: inherit;
  }
  
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  