/* updated.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');



.bodyfr {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(135deg, #7289da 0%, #4e5d94 100%);
  color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.containerfr {
  text-align: center;
  background-color: #2c2f33;
  padding: 40px 60px;
  border-radius: 15px;
  box-shadow: 8px 8px 15px #23272a, -8px -8px 15px #2c2f33;
}

.textfr {
  color: #ffffff;
  margin-bottom: 15px;
}

.login-button {
  background-color: #5865f2;
  color: #ffffff;
  font-size: 18px;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 4px 4px 10px #4752c4, -4px -4px 10px #6979f8;
  transition: transform 0.3s, box-shadow 0.3s;
}

.login-button:hover,
.login-button:focus {
  transform: translateY(-3px);
  box-shadow: 2px 2px 5px #4752c4, -2px -2px 5px #6979f8;
}

.login-button:active {
  transform: translateY(1px);
  box-shadow: inset 0 2px 5px rgba(0,0,0,0.2);
}
